var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"test"},[_c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{staticClass:"d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bground bgi-no-repeat bgi-size-contain bgi-attachment-fixed"},[_c('div',{staticClass:"d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"},[_c('a',{staticClass:"mb-5",attrs:{"href":"#"}}),_c('div',{staticClass:"w-sm-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto"},[_c('form',{staticClass:"w-100",attrs:{"id":"sign"},on:{"submit":function($event){$event.preventDefault();}}},[_vm._m(0),_c('div',{staticClass:"fv-row mb-10"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Password Baru")]),_c('validation-provider',{attrs:{"name":"Password","rules":{ required: true, min: 5, max: 64 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"password"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"placeholder":"Masukkan Password Baru","type":"password","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"fv-row mb-10"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Konfirmasi Password")]),_c('validation-provider',{attrs:{"name":"Konfirmasi Password","rules":{
                required: true,
                min: 5,
                max: 64,
                confirmed: 'Password',
              }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"confirm_password"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"placeholder":"Konfirmasi Password Anda","type":"password","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-lg btn-primary w-100",attrs:{"type":"submit","id":"sub"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('span',{staticClass:"indicator-label"},[_vm._v("Continue")]),_vm._m(1)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mb-10"},[_c('h1',{staticClass:"text-dark mb-3"},[_vm._v("Reset Password")]),_c('div',{staticClass:"text-gray-400 fw-bold fs-4"},[_vm._v("Buat Password Baru")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"indicator-progress"},[_vm._v("Please wait... "),_c('span',{staticClass:"spinner-border spinner-border-sm align-middle ms-2"})])
}]

export { render, staticRenderFns }