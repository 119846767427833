<template>
  <!-- <div v-if="isMobile">
    <Mobile />
  </div>
  <div v-else> -->
  <div v-if="isLoggedIn == 'main'">
    <component :is="selectedLayout">
      <router-view />
    </component>
  </div>
  <Login v-else-if="isLoggedIn == 'login'"></Login>
  <Register v-else-if="isLoggedIn == 'register'"></Register>
  <VerifikasiCode v-else-if="isLoggedIn == 'verifikasi'"></VerifikasiCode>
  <ForgotPassword v-else-if="isLoggedIn == 'forgot'"></ForgotPassword>
  <ResetPassword v-else-if="isLoggedIn == 'reset'"></ResetPassword>
  <ExportLembur v-else-if="isLoggedIn == 'lembur'"></ExportLembur>
  <!-- </div> -->
</template>

<script>
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import VerifikasiCode from "@/views/VerifikasiCode.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import ExportLembur from "@/views/Overtime/ExportLembur.vue";
import Alert from "@/components/Alert.vue";
import Swal from "sweetalert2";
// import { isMobile, isAndroid, isIOS } from "mobile-device-detect";
// import Mobile from "@/layouts/Mobile.vue";

export default {
  name: "App",
  components: {
    Login,
    Register,
    Alert,
    VerifikasiCode,
    ForgotPassword,
    ResetPassword,
    ExportLembur,
    // Mobile,
  },
  data() {
    return {
      // untuk memastikan satu modal yg muncul. Prevent 1 route mengakses banyak api & terjadi error di semua api yg akhirnya muncul banyak modal.
      msgBoxOk: false,
      // isMobile,
    };
  },
  computed: {
    selectedLayout() {
      return this.$route.meta.layout || "dash-layout";
    },
    isLoggedIn() {
      if (!!this.$store.state.token && !this.$store.state.register) {
        return "main";
      } else if (this.$store.state.auth.status == "reset") {
        return "reset";
      } else if (this.$store.state.auth.status == "register") {
        return "register";
      } else if (this.$store.state.auth.status == "verifikasi") {
        return "verifikasi";
      } else if (this.$store.state.auth.status == "forgot") {
        return "forgot";
      } else {
        // Check if user is on /lembur/export/:id
        if (this.$route.path.includes("/lembur/export/")) {
          if (this.$route.params.id) return "lembur";
        }
        return "login";
      }
    },
  },

  beforeCreate() {
    const isNoExpired = this.$store.state.exptimestamp > Date.now();
    const noNeed =
      !this.$store.state.token || (!!this.$store.state.token && isNoExpired);

    if (noNeed) {
      this.$store.commit("setInitAuthCheck", false);
    } else {
      this.$store.commit("setIsAuthCheckFailed", false);
      if (this.$store.state.token) {
        // diberi if agar tidak terjadi berkali-kali setToken kosong
        this.$store.commit("setToken", "");
        this.$store.commit("setExptimestamp", "");
      }
    }

    // if (isIOS || isAndroid) {
    //   if (isIOS) {
    //     window.location.replace("instagram://");

    //     setTimeout(() => {
    //       window.location.replace(
    //         "https://apps.apple.com/us/app/instagram/id389801252"
    //       );
    //     }, 10000);
    //   } else if (isAndroid) {
    //     // Open Instagram
    //     const url =
    //       "intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end";

    //     window.location.replace(url);
    //   }
    // }
  },
  // created: function () {
  // if (isIOS || isAndroid) {
  //   setTimeout(() => {
  //     if (isIOS) {
  //       window.location.replace("instagram://");
  //       setTimeout(() => {
  //         window.location.replace(
  //           "https://apps.apple.com/us/app/instagram/id389801252"
  //         );
  //       }, 10000);
  //     } else if (isAndroid) {
  //       // Open Instagram
  //       const url =
  //         "intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end";
  //       window.location.replace(url);
  //     }
  //   }, 2500);
  // }
  // },

  mounted() {
    // if (isMobile) {
    //   if (isAndroid) {
    //     window.location.href =
    //       "https://play.google.com/store/apps/details?id=com.whatsapp.w4b&hl=en";
    //     const url =
    //       "intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end";

    //     window.location.replace(url);
    //     // window.location.replace(
    //     //   "intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end"
    //     // );
    //   } else if (isIOS) {
    //     window.location.href("instagram://");
    //     setTimeout(() => {
    //       window.location.href(
    //         "https://apps.apple.com/us/app/instagram/id389801252"
    //       );
    //     }, 10000);
    //   }
    // }

    // notif by Modal Dialog
    this.$root.$on("notif-modal", (payload) => {
      const statusCode =
        typeof payload.statusCode === "number"
          ? `Error - ${payload.statusCode}`
          : payload.statusCode;
      const message = payload.message || "";
      const variant = payload.variant || "error";

      if (message && this.msgBoxOk == false) {
        this.msgBoxOk = true;
        Swal.fire({
          title: statusCode,
          html: message.data ? message.data.domProps.innerHTML : message,
          icon: variant,
          buttonsStyling: !1,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary order-2",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.msgBoxOk = false;
          }
        });
        // this.$modal
        //   .msgBoxOk(message, {
        //     title: statusCode,
        //     headerBgVariant: variant,
        //     okVariant: "secondary",
        //     headerClass: "text-white",
        //     bodyClass: "mb-8",
        //     footerClass: "border-top-0",
        //     centered: true,
        //     noCloseOnBackdrop: true,
        //   })
        //   .then(() => {
        //     this.msgBoxOk = false;
        //   })
        //   .catch(() => {
        //     this.msgBoxOk = false;
        //   });
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("notif-modal");
    this.$root.$off("main-alert");
  },
};
</script>
