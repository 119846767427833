<template>
  <div
    id="kt_aside"
    class="aside aside-light aside-hoverable"
    data-kt-drawer="true"
    data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
  >
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
      <router-link to="/">
        <div class="d-flex align-items-center mt-3 gap-5">
          <img alt="Logo" src="@/assets/img/bkm.jpg" class="h-40px logo" />
          <!-- <img alt="Logo" src="@/assets/img/logo.png" class="h-40px logo" /> -->
          <h3 class="logo mt-2">BKM Attendance</h3>
        </div>
      </router-link>

      <div
        id="kt_aside_toggle"
        class="btn btn-icon w-auto px-0 btn-active-color-success aside-toggle"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="aside-minimize"
      >
        <span class="svg-icon svg-icon-1 rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              opacity="0.5"
              d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
              fill="black"
            />
            <path
              d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
    </div>

    <div class="aside-menu flex-column-fluid">
      <div
        class="hover-scroll-overlay-y my-5 my-lg-5"
        id="kt_aside_menu_wrapper"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
        data-kt-scroll-wrappers="#kt_aside_menu"
        data-kt-scroll-offset="0"
      >
        <div
          class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
          id="#kt_aside_menu"
          data-kt-menu="true"
        >
          <template v-for="(node, indexRoot) in MenuSchema">
            <template v-if="inScope(node.roles) || node.roles == '*'">
              <template v-if="node.labelHeading">
                <div class="menu-item" :key="indexRoot">
                  <div class="menu-content pt-8 pb-2">
                    <span
                      class="menu-section text-muted text-uppercase fs-8 ls-1"
                      >{{ node.labelHeading }}</span
                    >
                  </div>
                </div>
                <template v-if="node.children">
                  <!--MENU ITEM support nested (recursive)-->
                  <template v-for="(child, index) in node.children">
                    <menu-item
                      v-if="inScope(child.roles) || inScope(child.roles) == '*'"
                      :key="index.id"
                      :item="child"
                    />
                  </template>
                </template>
              </template>

              <menu-item
                v-else
                :item="node"
                :key="`${indexRoot}-${node.label}`"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
    <div
      class="aside-footer flex-column-auto pt-5 pb-7 px-5"
      id="kt_aside_footer"
    ></div>
  </div>
</template>

<script>
import MenuSchema from "../router/menuSchema";
import MenuItem from "@/components/MenuItem";
export default {
  name: "AsideComponent",
  components: {
    MenuItem,
  },
  data() {
    return {
      isVisibleSidebar: false,
    };
  },
  created() {
    // non-reactive
    this.MenuSchema = MenuSchema;

    this.$root.$on("toggle-sidebar", (val) => {
      this.isVisibleSidebar = val;
    });
  },

  methods: {
    inScope(menu) {
      if (Array.isArray(menu)) {
        return menu.includes(`${this.user.role_id}`);
      } else {
        return menu;
      }
    },
  },
  computed: {
    user: function () {
      if (this.$store.state.token) {
        try {
          const decodedPayloadToken = JSON.parse(
            atob(this.$store.state.token.split(".")[1])
          );

          const user = {
            ...decodedPayloadToken,
            role_id: decodedPayloadToken.role_id,
          };
          this.$store.commit("setUser", user);
          return user;
        } catch (e) {
          return "Unknown User";
        }
      }
      return "Unknown User";
    },
  },
};
</script>

<style></style>
