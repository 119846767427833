<template>
  <div class="sign">
    <div class="d-flex flex-column flex-root">
      <div
        class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bground bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      >
        <div
          class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
        >
          <!-- <div class="row mb-10">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <img alt="Logo" src="../assets/img/bkm.jpg" class="h-90px" />
            </div>
          </div> -->
          <div
            v-if="theme != 'light'"
            href="#"
            class="mb-10 d-flex w-100 justify-content-center gap-10 flex-wrap"
          >
            <img
              alt="Logo"
              src="../assets/img/logo-dark.png"
              class="h-md-90px h-60px"
            />
            <img
              alt="Logo"
              src="../assets/img/er-dark.png"
              class="h-md-90px h-60px"
            />
          </div>
          <div
            v-else
            href="#"
            class="mb-10 d-flex w-100 justify-content-center gap-10 flex-wrap"
          >
            <img
              alt="Logo"
              src="../assets/img/logo1.png"
              class="h-md-90px h-60px"
            />
            <img
              alt="Logo"
              src="../assets/img/er.png"
              class="h-md-90px h-60px"
            />
          </div>
          <!-- <a href="#" class="mb-10"> </a> -->
          <div
            class="w-sm-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto"
          >
            <form class="w-100" id="sign" v-on:submit.prevent>
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">Sign In Absensi Pegawai</h1>
                <div class="text-gray-400 fw-bold fs-4">
                  Belum Punya Akun?
                  <a
                    @click="register"
                    style="cursor: pointer"
                    class="link-primary fw-bolder"
                    >Buat</a
                  >
                </div>
              </div>
              <div class="fv-row mb-10">
                <label class="form-label fw-bolder text-dark fs-6"
                  >Username</label
                >
                <validation-provider
                  name="Username"
                  :rules="{
                    required: true,
                    alpha_dash: true,
                    min: 4,
                    max: 64,
                  }"
                  v-slot="validationContext"
                >
                  <b-form-group label-for="username">
                    <b-form-input
                      tabindex="1"
                      id="username"
                      placeholder="Masukkan Username"
                      autocomplete="off"
                      class="form-control form-control-lg form-control-solid"
                      v-model="username"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="fv-row mb-10">
                <div class="d-flex flex-stack mb-2">
                  <label class="form-label fw-bolder text-dark fs-6 mb-0"
                    >Password</label
                  >

                  <a
                    tabindex="3"
                    href="#"
                    @click="reset"
                    class="link-primary fs-6 fw-bolder"
                    >Lupa Password ?</a
                  >
                </div>
                <validation-provider
                  name="Password"
                  :rules="{ required: true, min: 5, max: 64 }"
                  v-slot="validationContext"
                >
                  <b-form-group label-for="password">
                    <b-form-input
                      tabindex="2"
                      id="password"
                      type="password"
                      placeholder="Masukkan Password"
                      autocomplete="off"
                      class="form-control form-control-lg form-control-solid"
                      v-model="password"
                      :state="getValidationState(validationContext)"
                      trim
                    >
                    </b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  @click.stop.prevent="goLogin"
                  id="sub"
                  class="btn btn-lg btn-primary w-100"
                >
                  <span class="indicator-label">Continue</span>
                  <span class="indicator-progress"
                    >Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "LoginComponent",
  data() {
    return {
      username: "",
      password: "",
      inputpassword: true,
    };
  },
  computed: {
    theme() {
      return localStorage.getItem("theme");
    },
  },
  methods: {
    register() {
      this.$store.commit("setAuth", {
        status: "register",
      });
    },
    reset() {
      this.$store.commit("setAuth", {
        status: "forgot",
      });
    },
    goLogin() {
      this.$topprogressbar.start();
      var e = document.querySelector("#sub");
      e.setAttribute("data-kt-indicator", "on");
      /* make sure zero for clearly appear fade effect
      if do login many times with count down still running */
      this.alertDismissCountDown = 0;

      if (!this.username || !this.password) {
        this.errorText = "username & password harus diisi";
        this.$topprogressbar.fail();
        e.removeAttribute("data-kt-indicator");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Username & Password harus diisi",
          buttonsStyling: !1,
          confirmButtonText: "Coba lagi",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
      this.$axios
        .post(
          "/login",
          {
            username: this.username,
            password: this.password,
            web: "web",
          },
          this.$store.commit("setLoading", true)
        )
        .then((res) => {
          this.$store.commit("setLoading", false);
          if (res.data.token) {
            const decodedPayloadToken = JSON.parse(
              atob(res.data.token.split(".")[1])
            );

            const id_role = decodedPayloadToken.id_role;

            if (id_role == 3) {
              this.$topprogressbar.fail();
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Anda tidak memiliki akses",
                buttonsStyling: !1,
                confirmButtonText: "Coba lagi",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              e.removeAttribute("data-kt-indicator");
            } else {
              Swal.fire({
                title: "Login Berhasil",
                icon: "success",
                confirmButtonText: "OK",
                buttonsStyling: !1,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then((result) => {
                // https://play.google.com/store/apps/details?id=com.whatsapp.w4b&hl=en
                this.$axios.defaults.headers["Authorization"] =
                  "Bearer " + res.data.token;
                this.$store.commit("setToken", res.data.token);
                e.removeAttribute("data-kt-indicator");
                this.$topprogressbar.finish();
                if (result.isConfirmed) {
                  this.$router.go();
                }
              });
            }
          }
        })
        .catch((err) => {
          this.$topprogressbar.fail();
          e.removeAttribute("data-kt-indicator");
          this.$store.commit("setLoading", false);
          if (err.response) {
            if (err.response.status >= 400 && err.response.status < 500) {
              /* Error antara 400 - 499, biasanya kesalahan di user  */
              if (
                err.response.data.message ==
                "Email belum diverifikasi, apakah anda ingin mengirim ulang kode verifikasi?"
              ) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: err.response.data.message,
                  buttonsStyling: !1,
                  confirmButtonText: "Kirim Ulang",
                  showCancelButton: true,
                  cancelButtonText: "Nanti",
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-active-light-primary",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$topprogressbar.start();
                    this.$overlayloading.start();
                    this.$axios
                      .post("/send-verifikasi", {
                        email: err.response.data.error,
                      })
                      .then(() => {
                        this.$topprogressbar.finish();
                        this.$store.commit("setAuth", {
                          email: err.response.data.error,
                          status: "verifikasi",
                          from: "login",
                        });
                        Swal.fire({
                          title: "Kode Verifikasi Berhasil Dikirim",
                          icon: "success",
                          confirmButtonText: "OK",
                          buttonsStyling: !1,
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                        });
                      })
                      .catch((err) => {
                        this.$topprogressbar.fail();
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: err.response.data.message,
                          buttonsStyling: !1,
                          confirmButtonText: "Coba lagi",
                          customClass: {
                            confirmButton: "btn btn-primary",
                          },
                        });
                      });
                  }
                });
              } else {
                this.errorText = err.response.data.message || "Invalid Login";

                this.alertDismissCountDown = 30;
              }
            }

            // ERROR SELAIN ANTARA 400 - 499, biarkan ditampilkan by notif modal
            // liat interceptor axios
          }
        });
    },
    showHidePass() {
      this.inputpassword = !this.inputpassword;
    },
  },
};
</script>
