<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Signup Welcome Message -->
    <div class="d-flex flex-column flex-column-fluid">
      <!--begin::Content-->
      <div
        class="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15"
      >
        <!--begin::Logo-->
        <a class="pt-lg-10">
          <img
            alt="Logo"
            src="@/assets/img/bkm.jpg"
            class="mb-5"
            style="width: 200px"
          />
        </a>
        <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="pt-lg-10 mb-10">
          <!--begin::Logo-->
          <h1 class="fw-bolder fs-2qx text-gray-800 mb-7">Selamat Datang</h1>
          <!--end::Logo-->
          <!--begin::Message-->
          <div class="fw-bold fs-3 text-muted mb-15">
            Anda sedang menggunakan layanan pemeriksaan dokumen <br />
            <b>Lembur</b> secara online.
          </div>
          <!--end::Message-->
          <!--begin::Action-->
          <div class="text-center">
            Status dokumen anda saat ini adalah
            <span
              class="badge badge-success"
              :class="{
                'badge-success': data?.id_verifikator != null,
                'badge-danger': data?.id_verifikator == null,
              }"
            >
              {{
                data?.id_verifikator != null
                  ? `Terverifikasi oleh ${data?.name}`
                  : "Tidak Terverifikasi"
              }}
            </span>
          </div>
          <!--end::Action-->
        </div>
        <!--end::Wrapper-->
      </div>
    </div>
    <!--end::Authentication - Signup Welcome Message-->
  </div>
</template>

<script>
export default {
  name: "ExportLembur",
  data() {
    return {
      data: null,
    };
  },
  methods: {
    exportPdf() {
      this.$axios({
        method: "get",
        url: "/lembur-signature/file/" + this.$route.params.id,
        responseType: "blob",
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.data.file.split("/").pop());
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  mounted() {
    this.$axios
      .get("/lembur-signature/" + this.$route.params.id)
      .then((response) => {
        this.data = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
