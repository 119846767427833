var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.item.link ? _vm.item.link : '',"exact":_vm.item.isExactActive,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive, route }){return [(!_vm.item.children)?_c('div',{staticClass:"menu-item"},[_c('a',{class:[
        'menu-link',
        isActive ? 'active' : '',
        isExactActive ? 'active' : '',
      ],attrs:{"href":isActive ? '#' : href},on:{"click":navigate}},[_c('span',{staticClass:"menu-icon"},[_c('i',{class:[_vm.item.icon, 'fs-3']})]),_c('span',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.item.label))])])]):_c('div',{staticClass:"menu-item menu-accordion",class:_vm.item.children.find((x) => route.fullPath.includes(x.link))
        ? 'here show hover'
        : '',attrs:{"data-kt-menu-trigger":"click"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-icon"},[_c('i',{class:[_vm.item.icon, 'fs-3']})]),_c('span',{staticClass:"menu-title"},[_vm._v(_vm._s(_vm.item.label))]),_c('span',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-sub menu-sub-accordion",class:_vm.item.children.find((x) => route.fullPath.includes(x.link))
          ? 'show'
          : ''},_vm._l((_vm.item.children),function(child,id){return _c('menu-item',{key:id,attrs:{"item":child}})}),1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }