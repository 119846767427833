import Vue from "vue";
import Axios from "./axios-vue";

function install(vue) {
  if (install.installed) {
    return;
  }
  install.installed = true;

  function bind(el, binding, vnode) {
    Axios.get(binding.value, {
      responseType: "arraybuffer",
      disableErrNotif: true,
    })
      .then((res) => {
        let mimeType = res.headers["content-type"].toLowerCase();
        let imgBase64 = new Buffer(res.data, "binary").toString("base64");
        if (el.tagName === "IMG") {
          el.src = `data:${mimeType};base64,${imgBase64}`;
        } else {
          el.style.backgroundImage = `url(data:${mimeType};base64,${imgBase64})`;
          el.style.height = "90%";
          el.style.backgroundRepeat = "no-repeat";
          el.style.backgroundSize = "contain";
          el.style.backgroundPosition = "50%";
          el.style.backgroundColor = "transparent";
          el.style.opacity = "0.8";
        }

        vnode.context.$emit("auth-image-loaded", true);
      })
      .catch(() => {
        vnode.context.$emit("auth-image-loaded", true);
      });
  }

  function update(el, binding, vnode) {
    if (binding.value === binding.oldValue) {
      return;
    }

    bind(el, binding, vnode);
  }

  vue.directive("auth-image", {
    bind: bind,
    update: update,
  });
}

Vue.use(install);
