import Vue from "vue";
import Modal from "./Modal.vue";

const readonlyDescriptor = () => ({
  enumerable: true,
  configurable: false,
  writable: false,
});

// Fallback event resolver (returns undefined)
const defaultResolver = () => {};

function install(vue) {
  if (install.installed) {
    return;
  }
  install.installed = true;

  const MsgBoxExtendModal = vue.extend({
    name: "MsgBox",
    extends: Modal,
    destroyed() {
      // Make sure we not in document any more
      if (this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    },
    mounted() {
      // Self destruct handler
      const handleDestroy = () => {
        const self = this;
        this.$nextTick(() => {
          // In a `setTimeout()` to release control back to application
          setTimeout(() => self.$destroy(), 0);
        });
      };
      /* hook:destroyed & hook:beforeDestroy => lifecycle vue */
      this.$parent.$once("hook:destroyed", handleDestroy);
      this.$once("hidden", handleDestroy);
      if (this.$router && this.$route) {
        // Destroy ourselves if route changes
        /* istanbul ignore next */
        this.$once("hook:beforeDestroy", this.$watch("$router", handleDestroy));
      }

      this.show();
    },
  });

  const createMsgBox = (
    $parent,
    content,
    options = {},
    resolver = defaultResolver
  ) => {
    if (!content || typeof resolver !== "function") {
      return;
    }

    const msgBox = new MsgBoxExtendModal({
      parent: $parent,
      propsData: {
        ...options,
        noHeaderClose: true,
      },
    });

    msgBox.$slots.default = content;

    return new Promise((resolve, reject) => {
      let resolved = false;

      msgBox.$once("hook:destroyed", () => {
        if (!resolved) {
          reject(new Error("MsgBox destroyed before resolve"));
        }
      });

      msgBox.$on("hidden", (modalEvt) => {
        // if (!modalEvt.defaultPrevented) {
        //   const result = resolver(modalEvt);

        //   if (!modalEvt.defaultPrevented) {
        //     resolved = true;
        //     resolve(result);
        //   }
        // }

        const result = resolver(modalEvt);
        resolve(result);
      });

      // Create a mount point (a DIV) and mount the msgBo which will trigger it to show
      const div = document.createElement("div");
      document.body.appendChild(div);
      msgBox.$mount(div);
    });
  };

  class VModal {
    constructor(vm) {
      Object.assign(this, { _vm: vm, _root: vm.$root });
      Object.defineProperties(this, {
        _vm: readonlyDescriptor(),
        _root: readonlyDescriptor(),
      });
    }

    msgBoxOk(message, options) {
      const props = {
        noCloseOnBackdrop: false,
        ...options,
        // Add in overrides and our content prop
        noHeader: false,
        noFooter: false,
        okOnly: true,
      };

      return createMsgBox(this._vm, message, props, () => {
        return true;
      });
    }

    msgBoxConfirm(message, options) {
      const props = {
        noCloseOnBackdrop: true,
        ...options,
        // Add in overrides and our content prop
        noHeader: false,
        noFooter: false,
        okOnly: false,
      };

      return createMsgBox(this._vm, message, props, (modalEvt) => {
        const trigger = modalEvt.trigger;
        return trigger === "ok" ? true : trigger === "cancel" ? false : null;
      });
    }
  }

  // Add our instance mixin
  vue.mixin({
    beforeCreate() {
      // Because we need access to `$root` for `$emits`, and VM for parenting,
      // we have to create a fresh instance of `BvModal` for each VM
      this.__modal = new VModal(this);
    },
  });

  if (!Object.prototype.hasOwnProperty.call(vue.prototype, "$modal")) {
    Object.defineProperty(vue.prototype, "$modal", {
      get() {
        return this.__modal;
      },
    });
  }
}
Vue.component("MyModal", Modal);
Vue.use(install);
