<template>
  <div class="test">
    <div class="d-flex flex-column flex-root">
      <div
        class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bground bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" class="mb-5">
            <!-- <img alt="Logo" src="../assets/img/Logo Graha.png" class="h-90px" /> -->
          </a>
          <div class="w-sm-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form class="w-100" id="sign" v-on:submit.prevent>
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">Reset Password</h1>
                <div class="text-gray-400 fw-bold fs-4">Buat Password Baru</div>
              </div>
              <div class="fv-row mb-10">
                <label class="form-label fw-bolder text-dark fs-6">Password Baru</label>
                <validation-provider name="Password" :rules="{ required: true, min: 5, max: 64 }"
                  v-slot="validationContext">
                  <b-form-group label-for="password">
                    <b-form-input placeholder="Masukkan Password Baru" type="password" autocomplete="off"
                      class="form-control form-control-lg form-control-solid" v-model="password"
                      :state="getValidationState(validationContext)"></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="fv-row mb-10">
                <label class="form-label fw-bolder text-dark fs-6">Konfirmasi Password</label>
                <validation-provider name="Konfirmasi Password" :rules="{
                  required: true,
                  min: 5,
                  max: 64,
                  confirmed: 'Password',
                }" v-slot="validationContext">
                  <b-form-group label-for="confirm_password">
                    <b-form-input placeholder="Konfirmasi Password Anda" type="password" autocomplete="off"
                      class="form-control form-control-lg form-control-solid" v-model="confirm_password"
                      :state="getValidationState(validationContext)"></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="text-center">
                <button type="submit" @click.stop.prevent="login" id="sub" class="btn btn-lg btn-primary w-100">
                  <span class="indicator-label">Continue</span>
                  <span class="indicator-progress">Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ForgotPass",
  data() {
    return {
      password: "",
      confirm_password: "",
      msgBoxOk: false,
    };
  },

  methods: {
    login() {
      this.$topprogressbar.start();
      this.$overlayloading.start();
      this.$axios
        .post("/reset-forgot-password", {
          password: this.password,
          confirm_password: this.confirm_password,
          email: this.$store.state.auth.email,
        })
        .then(() => {
          this.$topprogressbar.finish();
          Swal.fire({
            icon: "success",
            title: "Password Berhasil Diubah",
            showConfirmButton: true,
            timer: 1500,
            buttonsStyling: !1,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.$store.commit("setAuth", {
            status: "login",
          });
        })
        .catch((error) => {
          this.$topprogressbar.fail();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
            buttonsStyling: !1,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    },
  },
};
</script>

<style>

</style>
