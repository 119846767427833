var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{staticClass:"d-flex flex-column flex-column-fluid bgi-no-repeat"},[_c('div',{staticClass:"d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"},[_vm._m(0),_c('div',{staticClass:"w-lg-700px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto"},[_c('form',{staticClass:"form w-100",attrs:{"novalidate":"novalidate","id":"kt_sign_up_form"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"mb-10 text-center"},[_c('h1',{staticClass:"text-dark mb-3"},[_vm._v("Sign Up Absensi Pegawai")]),_c('div',{staticClass:"text-gray-400 fw-bold fs-4"},[_vm._v(" Sudah Punya Akun? "),_c('a',{staticClass:"link-primary fw-bolder",attrs:{"href":"#"},on:{"click":_vm.login}},[_vm._v("Sign in")])])]),_c('div',{staticClass:"row fv-row mb-7"},[_c('div',{staticClass:"col-xl-6"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Badge Number")]),_c('validation-provider',{attrs:{"name":"Badge Number","rules":{ required: true, min: 5, max: 64 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"badge_number"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"badge_number","placeholder":"Masukkkan Badge Number","type":"number","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.badge_number),callback:function ($$v) {_vm.$set(_vm.user, "badge_number", $$v)},expression:"user.badge_number"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"col-xl-6"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Nama")]),_c('validation-provider',{attrs:{"name":"Nama","rules":{
                  required: true,
                }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"name"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"name","placeholder":"Masukkan Nama Lengkap...","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1)]),_c('div',{staticClass:"fv-row mb-7"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Username")]),_c('validation-provider',{attrs:{"name":"Username","rules":{
                required: true,
                min: 5,
                max: 64,
                alpha_dash: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"username"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"username","placeholder":"Buat Username...","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"fv-row mb-7"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":{
                required: true,
                email: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"email"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"email","placeholder":"Email anda...","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"fv-row mb-7"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("No Hp")]),_c('validation-provider',{attrs:{"name":"No Hp","rules":{
                required: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"no_hp"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"no_hp","placeholder":"08...","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.no_hp),callback:function ($$v) {_vm.$set(_vm.user, "no_hp", $$v)},expression:"user.no_hp"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"fv-row mb-7"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Jabatan")]),_c('validation-provider',{attrs:{"name":"Jabatan","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"jabatan"}},[_c('b-form-input',{staticStyle:{"display":"none"},attrs:{"type":"text","state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.id_jabatan),callback:function ($$v) {_vm.$set(_vm.user, "id_jabatan", $$v)},expression:"user.id_jabatan"}}),_c('v-select',{staticClass:"form-control p-2 form-control-solid",class:{
                    'is-invalid': !!validationContext.errors.length,
                  },attrs:{"options":_vm.jabatan,"loading":!_vm.jabatan.length,"reduce":(jabatan) => jabatan.id_jabatan,"placeholder":"Pilih Jabatan...","label":"nama_jabatan","required":!_vm.user.id_jabatan,"state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.id_jabatan),callback:function ($$v) {_vm.$set(_vm.user, "id_jabatan", $$v)},expression:"user.id_jabatan"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"fv-row mb-7"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Password")]),_c('validation-provider',{attrs:{"name":"Password","rules":{ required: true, min: 6, max: 64 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"password"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"password","type":"password","placeholder":"Buat Password baru","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"fv-row mb-7"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Confirm Password")]),_c('validation-provider',{attrs:{"name":"Konfirmasi Password","rules":{
                required: true,
                min: 6,
                max: 64,
                confirmed: 'Password',
              }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"confirm_password"}},[_c('b-form-input',{ref:"Password",staticClass:"form-control form-control-lg form-control-solid",attrs:{"id":"confirm_password","type":"password","data-vv-as":"password","placeholder":"Masukkan Password lagi...","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.user.confirm_password),callback:function ($$v) {_vm.$set(_vm.user, "confirm_password", $$v)},expression:"user.confirm_password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-lg btn-primary",attrs:{"type":"submit","id":"sub"},on:{"click":_vm.simpan}},[_c('span',{staticClass:"indicator-label"},[_vm._v("Submit")]),_vm._m(1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10 d-flex w-100 justify-content-center gap-10 flex-wrap",attrs:{"href":"#"}},[_c('img',{staticClass:"h-md-90px h-60px",attrs:{"alt":"Logo","src":require("../assets/img/logo1.png")}}),_c('img',{staticClass:"h-md-90px h-60px",attrs:{"alt":"Logo","src":require("../assets/img/er.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"indicator-progress"},[_vm._v("Please wait... "),_c('span',{staticClass:"spinner-border spinner-border-sm align-middle ms-2"})])
}]

export { render, staticRenderFns }