import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/lembur/export/:id?',
    name: 'Export Lembur',
    component: () => import(/* webpackChunkName: "home" */ '../views/Overtime/ExportLembur.vue')
  },
  {
    path: '/my-account',
    name: 'My Account',
    meta: {
      name: 'My Account',
    },
    component: () => import(/* webpackChunkName: "routes" */ '../views/MyAccount.vue'),
  },
  {
    path: "/karyawan",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Karyawan/Index.vue"),
    meta: {
      name: "Karyawan",
    },
    children: [
      {
        path: "/",
        name: "Karyawan",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Karyawan/Karyawan.vue"
          ),
      },
      {
        path: "detail/:id?",
        name: "Detail Karyawan",
        meta: {
          name: "Detail Karyawan",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Karyawan/DetailKaryawan.vue"
          ),
      },
      {
        path: "form/:id?",
        name: "Form Karyawan",
        meta: {
          name: "Form Karyawan",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Karyawan/FormKaryawan.vue"
          ),
      },
    ],
  },
  {
    path: "/jadwal",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Jadwal/Index.vue"),
    meta: {
      name: "Jadwal",
    },
    children: [
      {
        path: "/",
        name: "Jadwal",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Jadwal/Jadwal.vue"
          ),
      },
      {
        path: "harian",
        name: "Jadwal Harian",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Jadwal/JadwalHarian.vue"
          ),
      },
      {
        path: "form/:id?",
        name: "Form Tambah Jadwal",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Jadwal/FormJadwal.vue"
          ),
      },
      {
        path: "detail/:id?",
        name: "Detail Jadwal",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Jadwal/DetailJadwal.vue"
          ),
      },
      {
        path: "edit/:id?",
        name: "Edit Jadwal",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Jadwal/EditJadwal.vue"
          ),
      },
    ],
  },
  {
    path: "/setting-lembur",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/SettingLembur/Index.vue"),
    meta: {
      name: "Setting Lembur",
    },
    children: [
      {
        path: "/",
        name: "Setting Lembur",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/SettingLembur/SettingLembur.vue"
          ),
      }
    ],
  },
  {
    path: "/presensi",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Presensi/Index.vue"),
    meta: {
      name: "Presensi",
    },
    children: [
      {
        path: "/",
        name: "Presensi",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Presensi/Presensi.vue"
          ),
      },
      {
        path: "detail/:id?",
        name: "Detail Presensi",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Presensi/DetailPresensi.vue"
          ),
      }
    ],
  },
  {
    path: "/izin",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Izin/Index.vue"),
    meta: {
      name: "Izin Karyawan",
    },
    children: [
      {
        path: "/",
        name: "Izin Karyawan",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Izin/Izin.vue"
          ),
      },
      {
        path: "form/:id?",
        name: "Form Izin Karyawan",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Izin/FormIzin.vue"
          ),
      },
      {
        path: "detail/:id?",
        name: "Detail Izin Karyawan",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Izin/DetailIzin.vue"
          ),
      },
      {
        path: "acc-izin/:id?",
        name: "ACC Izin Karyawan",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Izin/AccIzin.vue"
          ),
      },
    ],
  },
  {
    path: "/official-duty",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Penugasan/Index.vue"),
    meta: {
      name: "Official Duty",
    },
    children: [
      {
        path: "/",
        name: "Official Duty",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Penugasan/Penugasan.vue"
          ),
      },
      {
        path: "form/:id?",
        name: "Official Duty Form",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Penugasan/FormPenugasan.vue"
          ),
      },
      {
        path: "detail/:id?",
        name: "Official Duty Detail",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Penugasan/DetailPenugasan.vue"
          ),
      },
      {
        path: "acc-official-duty/:id?",
        name: "ACC Official Duty",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Penugasan/AccPenugasan.vue"
          ),
      },
    ],
  },
  {
    path: "/tukar-shift",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/TukarShift/Index.vue"),
    meta: {
      name: "Tukar Shift",
    },
    children: [
      {
        path: "/",
        name: "Tukar Shift",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/TukarShift/TukarShift.vue"
          ),
      },
      {
        path: "form/:id?",
        name: "Form Tukar Shift",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/TukarShift/FormTukarShift.vue"
          ),
      },
      {
        path: "detail/:id?",
        name: "Detail Tukar Shift",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/TukarShift/DetailTukarShift.vue"
          ),
      },
      {
        path: "acc-tukar-shift/:id?",
        name: "ACC Tukar Shift",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/TukarShift/AccTukarShift.vue"
          ),
      },
    ],
  },
  {
    path: "/lembur",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Lembur/Index.vue"),
    meta: {
      name: "Lembur",
    },
    children: [
      {
        path: "/",
        name: "Lembur",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Lembur/Lembur.vue"
          ),
      },
      {
        path: "form/:id?",
        name: "Form Lembur",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Lembur/FormLembur.vue"
          ),
      },
      {
        path: "acc-lembur/:id?",
        name: "Acc Lembur",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Lembur/AccLembur.vue"
          ),
      },
      {
        path: "detail/:id?",
        name: "Detail Lembur",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Lembur/DetailLembur.vue"
          ),
      },
    ],
  },
  {
    path: "/overtime",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Overtime/Index.vue"),
    meta: {
      name: "Overtime",
    },
    children: [
      {
        path: "/",
        name: "Overtime",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Overtime/Lembur.vue"
          ),
      },
      {
        path: "form/:id?",
        name: "Form Overtime",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Overtime/FormLembur.vue"
          ),
      },
      {
        path: "acc-lembur/:id?",
        name: "Acc Overtime",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Overtime/AccLembur.vue"
          ),
      },
      {
        path: "detail/:id?",
        name: "Detail Overtime",
        component: () =>
          import(
            /* webpackChunkName: "routes" */ "@/views/Overtime/DetailLembur.vue"
          ),
      },
    ],
  },



  // CRUD One Page
  {
    path: "/jabatan",
    name: "Jabatan",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Jabatan.vue"),
  },
  {
    path: "/report",
    name: "Report",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Report.vue"),
  },
  {
    path: "/overtime-rate",
    name: "Overtime Rates",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/RateOvertime.vue"),
  },
  {
    path: "/jenis-karyawan",
    name: "Jenis Karyawan",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/JenisKaryawan.vue"),
  },
  {
    path: "/lokasi",
    name: "Lokasi",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Lokasi.vue"),
  },
  {
    path: "/lokasi-karyawan",
    name: "Setting Lokasi Karyawan",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/LokasiKaryawan.vue"),
  },
  {
    path: "/setting-tahun",
    name: "Setting Tahun",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/TahunAktif.vue"),
  },
  {
    path: "/verifikasi-user",
    name: "Verifikasi User",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/VerifikasiUser.vue"),
  },
  {
    path: "/jenis-shift",
    name: "Jenis Shift",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/JenisShift.vue"),
  },
  {
    path: "/shift",
    name: "Shift",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Shift.vue"),
  },
  {
    path: "/setting-keterlambatan",
    name: "Setting Keterlambatan",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/ShiftSetting.vue"),
  },
  {
    path: "/jenis-izin",
    name: "Jenis Izin",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/JenisIzin.vue"),
  },
  {
    path: "/export",
    name: "Export",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Export.vue"),
  },
  // {
  //   path: "/presensi",
  //   name: "Presensi",
  //   component: () =>
  //     import(/* webpackChunkName: "routes" */ "@/views/Presensi.vue"),
  // },
  {
    path: "/log-activity",
    name: "Log Activity",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/LogActivity.vue"),
  },
  {
    path: "/role",
    name: "Role",
    component: () =>
      import(/* webpackChunkName: "routes" */ "@/views/Role.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const before = from.path.split("/")[1];
  const after = to.path.split("/")[1];
  if (before != after) {
    store.commit("setMoreFilters", {});
  }
  next();
})


export default router
