var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign"},[_c('div',{staticClass:"d-flex flex-column flex-root"},[_c('div',{staticClass:"d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bground bgi-no-repeat bgi-size-contain bgi-attachment-fixed"},[_c('div',{staticClass:"d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"},[(_vm.theme != 'light')?_c('div',{staticClass:"mb-10 d-flex w-100 justify-content-center gap-10 flex-wrap",attrs:{"href":"#"}},[_c('img',{staticClass:"h-md-90px h-60px",attrs:{"alt":"Logo","src":require("../assets/img/logo-dark.png")}}),_c('img',{staticClass:"h-md-90px h-60px",attrs:{"alt":"Logo","src":require("../assets/img/er-dark.png")}})]):_c('div',{staticClass:"mb-10 d-flex w-100 justify-content-center gap-10 flex-wrap",attrs:{"href":"#"}},[_c('img',{staticClass:"h-md-90px h-60px",attrs:{"alt":"Logo","src":require("../assets/img/logo1.png")}}),_c('img',{staticClass:"h-md-90px h-60px",attrs:{"alt":"Logo","src":require("../assets/img/er.png")}})]),_c('div',{staticClass:"w-sm-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto"},[_c('form',{staticClass:"w-100",attrs:{"id":"sign"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"text-center mb-10"},[_c('h1',{staticClass:"text-dark mb-3"},[_vm._v("Sign In Absensi Pegawai")]),_c('div',{staticClass:"text-gray-400 fw-bold fs-4"},[_vm._v(" Belum Punya Akun? "),_c('a',{staticClass:"link-primary fw-bolder",staticStyle:{"cursor":"pointer"},on:{"click":_vm.register}},[_vm._v("Buat")])])]),_c('div',{staticClass:"fv-row mb-10"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6"},[_vm._v("Username")]),_c('validation-provider',{attrs:{"name":"Username","rules":{
                  required: true,
                  alpha_dash: true,
                  min: 4,
                  max: 64,
                }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"username"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"tabindex":"1","id":"username","placeholder":"Masukkan Username","autocomplete":"off","state":_vm.getValidationState(validationContext)},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"fv-row mb-10"},[_c('div',{staticClass:"d-flex flex-stack mb-2"},[_c('label',{staticClass:"form-label fw-bolder text-dark fs-6 mb-0"},[_vm._v("Password")]),_c('a',{staticClass:"link-primary fs-6 fw-bolder",attrs:{"tabindex":"3","href":"#"},on:{"click":_vm.reset}},[_vm._v("Lupa Password ?")])]),_c('validation-provider',{attrs:{"name":"Password","rules":{ required: true, min: 5, max: 64 }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label-for":"password"}},[_c('b-form-input',{staticClass:"form-control form-control-lg form-control-solid",attrs:{"tabindex":"2","id":"password","type":"password","placeholder":"Masukkan Password","autocomplete":"off","state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-lg btn-primary w-100",attrs:{"type":"submit","id":"sub"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goLogin.apply(null, arguments)}}},[_c('span',{staticClass:"indicator-label"},[_vm._v("Continue")]),_vm._m(0)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"indicator-progress"},[_vm._v("Please wait... "),_c('span',{staticClass:"spinner-border spinner-border-sm align-middle ms-2"})])
}]

export { render, staticRenderFns }