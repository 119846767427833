<template>
  <TransporterSingle v-if="isVisible">
    <div style="position: absolute" :style="{ zIndex }">
      <Transition
        name="modal-fade"
        mode="out-in"
        v-bind:css="true"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div
          v-if="isShow"
          :id="modalId"
          role="dialog"
          class="modal"
          :class="isShow && 'fade show'"
          :style="isShow ? 'display: block;' : 'display: none;'"
          aria-modal="true"
          key="modal"
          @click.stop="onClickOut"
          @keydown="onEsc"
        >
          <div class="modal-dialog" :class="dialogClasses">
            <span tabindex="0"></span>
            <div
              ref="content"
              class="modal-content rounded"
              tabindex="-1"
              :class="contentClass"
            >
              <div v-if="!noHeader" class="modal-header" :class="headerClasses">
                <h5 class="modal-title" :class="headerClasses">
                  <slot name="title">
                    {{ title }}
                  </slot>

                  <template v-if="subtitle != ''">
                    <br />
                    <small class="text-muted">
                      {{ subtitle }}
                    </small>
                  </template>
                </h5>
                <button
                  v-if="!noHeaderClose"
                  type="button"
                  aria-label="Close"
                  class="btn btn-sm btn-icon btn-active-color-primary close"
                  @click.stop.prevent="onClose"
                >
                  <span class="svg-icon svg-icon-1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.5"
                        x="6"
                        y="17.3137"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="7.41422"
                        y="6"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>
                </button>
              </div>

              <div class="modal-body" :class="bodyClass">
                <slot></slot>
              </div>

              <div v-if="!noFooter" class="modal-footer" :class="footerClass">
                <slot name="footer">
                  <button
                    v-if="!okOnly"
                    type="button"
                    class="btn"
                    :class="`btn-${cancelVariant}`"
                    @click.stop.prevent="onCancel"
                  >
                    {{ cancelTitle }}
                  </button>
                  <button
                    type="button"
                    class="btn"
                    id="loading-modal"
                    :class="`btn-${okVariant}`"
                    @click.stop.prevent="onOk"
                  >
                    <span class="indicator-label">{{ okTitle }}</span>
                    <span class="indicator-progress"
                      >Loading...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                </slot>
              </div>
            </div>
            <span tabindex="0"></span>
          </div>
        </div>
      </Transition>

      <transition
        enter-to-class="show"
        leave-class="show"
        enter-active-class="fade"
        leave-active-class="fade"
      >
        <div
          v-if="isShow && !isMultiModal"
          class="modal-backdrop"
          key="backdrop"
        ></div>
      </transition>
    </div>
  </TransporterSingle>
</template>

<script>
import { TransporterSingle } from "../../utils/transporter";
import { ModalManager } from "./ModalManager";
// import KeyCodes from '../../utils/key-codes';

export default {
  name: "ModalComponent",
  inheritAttrs: false,

  components: {
    TransporterSingle,
  },

  model: {
    prop: "visible",
    event: "change",
  },

  props: {
    id: {
      type: String,
      // default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "md",
    },
    centered: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    noHeaderClose: {
      type: Boolean,
      default: false,
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    okOnly: {
      type: Boolean,
      default: false,
    },
    okVariant: {
      type: String,
      default: "primary",
    },
    cancelVariant: {
      type: String,
      default: "secondary",
    },
    okTitle: {
      type: String,
      default: "Ok",
    },
    cancelTitle: {
      type: String,
      default: "Cancel",
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    /* class */
    headerBgVariant: {
      type: String,
      // default: undefined,
    },
    headerClass: {
      type: [String, Array, Object],
      // default: null
    },
    contentClass: {
      type: [String, Array, Object],
      // default: null
    },
    bodyClass: {
      type: [String, Array, Object],
      // default: null
    },
    footerClass: {
      type: [String, Array, Object],
      // default: null
    },
  },

  data() {
    return {
      zIndex: 1040,
      isVisible: false,
      isShow: false, // Used for style control
      isMultiModal: false,
    };
  },

  watch: {
    visible(newVal, oldVal) {
      if (newVal !== oldVal) {
        this[newVal ? "show" : "hide"]();
        this.$emit("change", newVal);
      }
    },
  },

  computed: {
    modalId() {
      return this.id || "__MODAL__" + this._uid;
    },
    dialogClasses() {
      return [
        {
          [`modal-${this.size}`]: this.size,
          "modal-dialog-centered": this.centered,
          "modal-dialog-scrollable": this.scrollable,
        },
      ];
    },

    headerClasses() {
      return [
        {
          [`bg-${this.headerBgVariant}`]: this.headerBgVariant,
        },
        this.headerClass,
      ];
    },
  },

  created() {
    this.modalEvent = {};
  },

  mounted() {
    // Initially show modal?
    if (this.visible === true) {
      this.$nextTick(this.show);
    }
  },

  methods: {
    show() {
      if (this.isVisible /* || this.isOpening */) {
        // If already open, or in the process of opening, do nothing
        /* istanbul ignore next */
        return;
      }

      this.isVisible = true;

      if (ModalManager.modalCount > 1) {
        this.isMultiModal = true;
      }

      this.$nextTick(() => {
        this.isShow = true;
      });
    },

    hide(trigger = "") {
      // trigger ini digunakan untuk value dari emit agar parent tau, seperti apakah ok/cancel
      this.modalEvent = {
        target: this.$refs.modal || this.$el || null,
        trigger,
      };

      this.isShow = false;
    },

    onOk() {
      var e = document.querySelector("#loading-modal");
      e.setAttribute("data-kt-indicator", "on");
      this.hide("ok");
    },

    onCancel() {
      this.hide("cancel");
    },

    onClose() {
      this.hide("headerclose");
      this.$emit("closeModal", false);
    },

    onClickOut(evt) {
      if (!this.isVisible || this.noCloseOnBackdrop) {
        return;
      }

      const isClickModalSelf =
        this.$refs.content && typeof this.$refs.content.contains === "function"
          ? this.$refs.content.contains(evt.target)
          : false;

      if (!isClickModalSelf) {
        this.hide();
      }
    },

    onEsc(evt) {
      // if (evt.keyCode === KeyCodes.ESC && this.isVisible && !this.noCloseOnEsc) {
      if (evt.keyCode === 27 && this.isVisible && !this.noCloseOnEsc) {
        this.hide("esc");
      }
    },

    // -----------------------
    // TRANSITION ENTERING
    // -----------------------
    beforeEnter: function () {
      // console.log('beforeEnter');

      this.$nextTick(() => {
        this.$emit("show");
        this.$root.$emit("modal::show", this.id);
      });
    },

    enter: function () {
      // console.log('enter');

      /* class modal-open ngilangin scroll di body jika ada */
      // document.body.classList.add('modal-open');
      ModalManager.registerModal(this);
    },

    afterEnter: function () {
      // console.log('afterEnter');

      this.$nextTick(() => {
        this.$refs.content.focus(); // agar bisa eksekusi tombol ESC

        this.$emit("shown");
        this.$root.$emit("modal::shown", this.id);
      });
    },

    // -----------------------
    // TRANSITION LEAVING
    // -----------------------
    beforeLeave: function () {
      // console.log('beforeLeave');

      this.$nextTick(() => {
        this.$emit("hide");
        this.$root.$emit("modal::hide", this.id);
      });
    },

    leave: function () {
      // console.log('leave');

      /* class modal-open balikin scroll di body jika ada */
      // document.body.classList.remove('modal-open');
      ModalManager.unregisterModal(this);
    },

    afterLeave: function () {
      // console.log('afterLeave');

      this.$nextTick(() => {
        this.isVisible = false;
        this.$emit("hidden", this.modalEvent);
        this.$root.$emit("modal::hidden", this.id);
      });
    },

    /* createModal(h) {
      let $header = h();
      if (!this.noHeader) {
        let $closeButton = h();
        if (!this.noHeaderClose) {
          $closeButton = h('button', {
            staticClass: 'close',
            attrs: { type: 'button', 'aria-label': 'close' },
            domProps: { innerHTML: '&times;' },
            on: { click: this.onClose },
          });
        }

        $header = h(
          'header',
          {
            staticClass: 'modal-header',
            // class: this.headerClasses,
            // attrs: { id: this.modalHeaderId },
            ref: 'header',
          },
          [
            h('h5', {
              staticClass: 'modal-title',
              domProps: { innerHTML: this.title },
            }),
            $closeButton,
          ]
        );
      }

      // Modal body
      const $body = h(
        'div',
        {
          staticClass: 'modal-body',
          // class: this.bodyClasses,
          // attrs: { id: this.modalBodyId },
          ref: 'body',
        },
        // this.normalizeSlot('default', this.slotScope)
        this.$slots.default
      );

      // Modal footer
      let $footer = h();
      if (!this.noFooter) {
        $footer = h('footer', {
          staticClass: 'modal-footer',
        });
      }

      // Assemble modal content
      const $modalContent = h(
        'div',
        {
          staticClass: 'modal-content',
          // class: this.contentClass,
          attrs: {
            id: this.modalContentId,
            tabindex: '-1',
          },
          ref: 'content',
        },
        [$header, $body, $footer]
      );

      const $modalDialog = h(
        'div',
        {
          staticClass: 'modal-dialog',
          class: `modal-${this.size} modal-dialog-scrollable`,
          // on: { mousedown: this.onDialogMousedown },
          ref: 'dialog',
        },
        // [$tabTrapTop, $modalContent, $tabTrapBottom]
        [$modalContent]
      );

      let $modal = h();
      if (this.isShow) {
        $modal = h(
          'div',
          {
            staticClass: 'modal',
            class: 'fade show',
            // style: this.modalStyles,
            style: { display: 'block' },
            // attrs: this.computedModalAttrs,
            attrs: { role: 'dialog' },
            on: { keydown: this.onEsc, click: this.onClickOut },
            // on: { click: this.onClickOut },
            directives: [{ name: 'show', value: this.isShow }],
            ref: 'modal',
          },
          [$modalDialog]
        );
      }
      $modal = h(
        'transition',
        {
          props: {
            name: 'slide-fade',
          },
          on: {
            beforeEnter: this.beforeEnter,
            enter: this.enter,
            afterEnter: this.afterEnter,
            beforeLeave: this.beforeLeave,
            leave: this.leave,
            afterLeave: this.afterLeave,
          },
        },
        [$modal]
      );

      // Modal backdrop
      let $backdrop = h();
      if (this.isShow) {
        $backdrop = h('div', {
          staticClass: 'modal-backdrop',
        });
      }
      $backdrop = h(
        'transition',
        {
          props: {
            enterToClass: 'show',
            leaveClass: 'show',
            enterActiveClass: 'fade',
            leaveActiveClass: 'fade',
          },
        },
        [$backdrop]
      );

      return h(
        'div',
        {
          style: { position: 'absolute', zIndex: 1040 },
          // attrs: {},
          key: 'modal-outer',
        },
        [$modal, $backdrop]
      );
    }, */
  },

  // render(h) {
  //   // return this.isVisible ? this.createModal(h) : h();
  //   return this.isVisible ? h(TransporterSingle, [this.createModal(h)]) : h();
  // },
};
</script>
