<template>
  <div class="sign">
    <div class="d-flex flex-column flex-root">
      <div
        class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bground bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
          <a href="#" class="mb-5">
            <!-- <img alt="Logo" src="../assets/img/Logo Graha.png" class="h-90px" /> -->
          </a>
          <div class="w-sm-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form class="w-100" id="sign" v-on:submit.prevent>
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">Lupa Password ?</h1>
                <div class="text-gray-400 fw-bold fs-4">
                  Masukkan email untuk mereset password. atau ke halaman
                  <a @click="login" style="cursor: pointer" class="link-primary fw-bolder">
                    Sign In?
                  </a>
                </div>
              </div>
              <div class="fv-row mb-10">
                <label class="form-label fw-bolder text-dark fs-6">Email</label>
                <validation-provider name="Email" :rules="{
                  required: true,
                  email: true,
                }" v-slot="validationContext">
                  <b-form-group label-for="email">
                    <b-form-input id="email" placeholder="Masukkan Email" autocomplete="off"
                      class="form-control form-control-lg form-control-solid" v-model="email"
                      :state="getValidationState(validationContext)"></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="text-center">
                <button type="submit" @click.stop.prevent="simpan" id="sub" class="btn btn-lg btn-primary w-100">
                  <span class="indicator-label">Continue</span>
                  <span class="indicator-progress">Please wait...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "LoginComponent",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    login() {
      this.$store.commit("setAuth", {
        status: "login",
      });
    },

    simpan() {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Pastikan email yang anda masukkan benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Kirim!",
        cancelButtonText: "Tidak, Batalkan!",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light-primary btn-light-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$topprogressbar.start();
          this.$overlayloading.start();
          this.$axios({
            method: "post",
            url: "/forgot-password",
            data: {
              email: this.email,
            },
          })
            .then(() => {
              this.$topprogressbar.finish();
              this.$overlayloading.finish();
              this.$store.commit("setAuth", {
                status: "verifikasi",
                email: this.email,
                from: "forgot",
              });
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Silahkan cek email anda",
                buttonsStyling: !1,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            })
            .catch((err) => {
              this.$topprogressbar.fail();
              Swal.fire({
                icon: "error",
                title: "Gagal",
                text: err.response.data.message,
                buttonsStyling: !1,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    },
  },
};
</script>
