import Vue from 'vue'
import Vuetify, {
  VApp,
  VSheet,
  VBtn,
  VIcon,
  VSpacer,
  VMenu,
  VList,
  VListItem,
  VListItemTitle,
  VCalendar,
  VCard,
  VToolbarTitle,
  VCardText,
  VCardActions,
  VToolbar,
} from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import id from 'vuetify/es5/locale/id'

Vue.use(Vuetify, {
  components: {
    VApp,
    VSheet,
    VBtn,
    VIcon,
    VSpacer,
    VMenu,
    VList,
    VListItem,
    VListItemTitle,
    VCalendar,
    VCard,
    VToolbarTitle,
    VCardText,
    VCardActions,
    VToolbar,
  },
  iconfont: 'mdi',
})

const getMatchMedia = () => {
  return localStorage.getItem('theme') === 'dark' ? true : false
}

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { id },
    current: 'id',
  },
  theme: {
    dark: getMatchMedia() || false,
  },
}

export default new Vuetify(opts)