<template>
  <div class="sign">
    <div class="d-flex flex-column flex-root">
      <div
        class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bground bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      >
        <div
          class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
        >
          <a href="#" class="mb-5">
            <!-- <img alt="Logo" src="../assets/img/Logo Graha.png" class="h-90px" /> -->
          </a>
          <div
            class="w-sm-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto"
          >
            <form class="w-100" id="sign" v-on:submit.prevent>
              <div class="text-center mb-10">
                <h1 class="text-dark mb-3">Verifikasi Email</h1>
                <div class="text-gray-400 fw-bold fs-4">
                  Masukkan Kode Verifikasi yang telah dikirimkan ke email
                  {{ email }}.
                  <a
                    @click="register"
                    style="cursor: pointer"
                    class="link-primary fw-bolder"
                    v-if="$store.state.auth.from != 'login'"
                    >Email Salah?</a
                  >
                </div>
              </div>
              <div class="fv-row mb-10">
                <div class="d-flex flex-stack mb-2">
                  <label class="form-label fw-bolder text-dark fs-6 mb-0"
                    >Kode Verifikasi</label
                  >

                  <a
                    tabindex="3"
                    href="#"
                    @click="send"
                    class="link-primary fs-6 fw-bolder"
                    >Kirim Ulang ?</a
                  >
                </div>
                <validation-provider
                  name="Kode Verifikasi"
                  :rules="{
                    required: true,
                    min: 6,
                    max: 6,
                  }"
                  v-slot="validationContext"
                >
                  <b-form-group label-for="verifikasi_code">
                    <b-form-input
                      id="verifikasi_code"
                      placeholder="Masukkan Kode Verifikasi"
                      autocomplete="off"
                      class="form-control form-control-lg form-control-solid"
                      v-model="verifikasi_code"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="text-center">
                <button
                  type="submit"
                  @click.stop.prevent="simpan"
                  id="sub"
                  class="btn btn-lg btn-primary w-100"
                >
                  <span class="indicator-label">Continue</span>
                  <span class="indicator-progress"
                    >Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swal from 'sweetalert2';

import Swal from "sweetalert2";

export default {
  name: "LoginComponent",
  data() {
    return {
      verifikasi_code: "",
    };
  },
  methods: {
    register() {
      if (this.$store.state.auth.from) {
        this.$store.commit("setAuth", {
          status: "forgot",
        });
      } else {
        console.log(this.$store.state.auth);
        this.$store.commit("setAuth", {
          ...this.$store.state.auth,
          status: "register",
        });
      }
    },
    simpan() {
      var e = document.querySelector("#sub");
      e.setAttribute("data-kt-indicator", "on");
      this.$topprogressbar.start();
      this.$overlayloading.start();
      this.$axios({
        method: "POST",
        url: "/verifikasi-email",
        data: {
          email: this.$store.state.auth.email,
          verification_code: this.verifikasi_code,
        },
      })
        .then(() => {
          e.removeAttribute("data-kt-indicator");
          this.$topprogressbar.finish();
          this.$overlayloading.submit();

          if (this.$store.state.auth.from) {
            if (this.$store.state.auth.from != "login") {
              this.$store.commit("setAuth", {
                status: "reset",
                email: this.$store.state.auth.email,
              });
            } else {
              this.$store.commit("setAuth", {
                status: "login",
              });
            }
          } else {
            this.$store.commit("setAuth", {
              status: "login",
            });
          }
        })
        .catch(() => {
          e.removeAttribute("data-kt-indicator");
          this.$topprogressbar.fail();
        });
    },
    send() {
      Swal.fire({
        icon: "warning",
        title: "Kirim Ulang Kode Verifikasi?",
        text: "Kode verifikasi akan dikirim ulang ke email " + this.email + ".",
        buttonsStyling: !1,
        confirmButtonText: "Kirim Ulang",
        showCancelButton: true,
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$topprogressbar.start();
          this.$overlayloading.start();
          this.$axios
            .post("/send-verifikasi", {
              email: this.$store.state.auth.email,
            })
            .then(() => {
              this.$store.commit("setAuth", {
                email: this.$store.state.auth.email,
                status: "verifikasi",
                from: this.$store.state.auth.from,
              });
              Swal.fire({
                title: "Kode Verifikasi Berhasil Dikirim",
                icon: "success",
                confirmButtonText: "OK",
                buttonsStyling: !1,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              this.$topprogressbar.finish();
            })
            .catch((err) => {
              this.$topprogressbar.fail();
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: err.response.data.message,
                buttonsStyling: !1,
                confirmButtonText: "Coba lagi",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    },
  },

  computed: {
    email() {
      return this.$store.state.auth.email.replace(
        /^(.{2})(.*)(@.*)$/,
        "$1****$3"
      );
    },
  },
};
</script>
