<template>
  <transition name="fade">
    <div v-if="visible" class="alert ms-8 me-8 alert-dismissible border d-flex flex-column flex-sm-row p-5 mb-10"
      :class="`bg-light-${variant} border-${variant}`">
      <!--begin::Wrapper-->
      <div class="d-flex flex-column pe-0 pe-sm-10">
        <!--begin::Title-->
        <h5 :class="`mb-1 text-${variant}`">Information</h5>
        <!--end::Title-->
        <!--begin::Content-->
        <span v-html="message ? message : '-'" :class="`text-${variant}`"></span>
        <!--end::Content-->
      </div>
      <!--end::Wrapper-->

      <!--begin::Close-->
      <button type="button"
        class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
        data-bs-dismiss="alert" @click.stop.prevent="dismiss">
        <i class="bi bi-x fs-1" :class="`text-${variant}`"></i>
      </button>
      <!--end::Close-->
    </div>
  </transition>
</template>

<script>
const toInteger = (value, defaultValue = 0) => {
  const integer = parseInt(value, 10);
  return isNaN(integer) ? defaultValue : integer;
};

// Convert `show` value to a number
const parseShowToNumberCountDown = (show) => {
  if (show === '' || typeof show === 'boolean') {
    return 0;
  }
  show = toInteger(show, 0);
  return show > 0 ? show : 0;
};

// Convert `show` value to a boolean
const parseShowToBooleanVisible = (show) => {
  if (show === '' || show === true) {
    return true;
  }

  if (toInteger(show, 0) < 1) {
    // Boolean will always return false for the above comparison
    return false;
  }

  return !!show;
};

export default {
  name: 'AlertComponent',
  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    variant: {
      type: [String, Object],
      default: 'info',
    },
    message: {
      type: [String, Object],
      default: null,
    },
    show: {
      type: [Boolean, Number, String],
      default: false,
    },
  },

  data() {
    return {
      visible: false,
      countDown: 0,
      countDownTimeout: null,
    };
  },

  watch: {
    show(newVal) {
      this.visible = parseShowToBooleanVisible(newVal);
      this.countDown = parseShowToNumberCountDown(newVal);
    },

    countDown(newVal) {
      this.clearCountDownInterval();

      if (this.show !== newVal) {
        // Update the v-model if needed
        this.$emit('input', newVal);
      }

      if (newVal > 0) {
        this.countDownTimeout = setTimeout(() => {
          this.countDown--;
        }, 1000);
      } else {
        this.$nextTick(() => {
          this.visible = false;
        });
      }
    },
  },

  methods: {
    dismiss() {
      this.clearCountDownInterval();
      this.visible = false;
      this.countDown = 0;
    },

    clearCountDownInterval() {
      if (this.countDownTimeout) {
        clearTimeout(this.countDownTimeout);
        this.countDownTimeout = null;
      }
    },
  },

  created() {
    this.visible = parseShowToBooleanVisible(this.show);
    this.countDown = parseShowToNumberCountDown(this.show);
  },

  beforeDestroy() {
    this.clearCountDownInterval();
  },
};
</script>
