<template>
  <div class="dashboard">
    <div class="d-flex flex-column flex-root">
      <!--begin::Page-->
      <div class="page d-flex flex-row flex-column-fluid">
        <Aside />
        <!--begin::Wrapper-->
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <!--begin::Content-->
          <div
            class="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <!--begin::Post-->
            <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
              <!--begin::Container-->
              <div class="d-flex flex-column flex-column-fluid">
                <Alert
                  class="main-alert app-container"
                  :message="mainAlert.message"
                  :variant="mainAlert.variant"
                  v-model="mainAlert.dismissCountDown"
                ></Alert>
                <div class="container-lg">
                  <transition-group
                    name="view"
                    mode="out-in"
                    v-bind:css="true"
                    @before-enter="unsetOverflow"
                    @before-leave="hideOverflow"
                  >
                    <main :key="$route.path">
                      <Breadcrumb />
                      <slot name="headerTitle"></slot>
                      <slot></slot>
                    </main>
                    <!-- <router-view></router-view> -->
                  </transition-group>
                </div>
              </div>
              <!--end::Container-->
            </div>
            <!--end::Post-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Page-->
    </div>
    <!--end::Root-->
    <!--begin::Scrolltop-->
    <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
      <!--begin::Svg Icon | path: icons/duotune/arrows/arr066.svg-->
      <span class="svg-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect
            opacity="0.5"
            x="13"
            y="6"
            width="13"
            height="2"
            rx="1"
            transform="rotate(90 13 6)"
            fill="black"
          />
          <path
            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
            fill="black"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>
  </div>
</template>

<script>
import Aside from "@/components/Aside.vue";
import Header from "./Header.vue";
import Alert from "@/components/Alert.vue";
import toastr from "toastr";

export default {
  name: "DashLayout",
  components: {
    Aside,
    Header,
    Alert,
    Breadcrumb: () => import("@/components/Breadcrumb.vue"),
  },
  data() {
    return {
      mainAlert: {
        variant: "danger",
        message: "",
        dismissCountDown: 0,
      },
      isOverflowHidden: false,
      alert: null,
    };
  },
  methods: {
    unsetOverflow() {
      this.isOverflowHidden = false;
    },

    hideOverflow() {
      this.isOverflowHidden = true;
    },
  },
  mounted() {
    // notif by Alert (embedded in page)
    this.$root.$on("main-alert", (payload) => {
      /* make sure zero for clearly appear fade effect
      if do emit many times with count down still running */
      if (this.mainAlert.dismissCountDown > 0) {
        this.mainAlert.dismissCountDown = 0;
      }

      setTimeout(() => {
        this.mainAlert = { dismissCountDown: 10, ...payload };
      }, 100);
    });

    // this.$channel.bind("App\\Events\\Alert", (data) => {
    //   // console.log(data);
    //   this.alert = data;
    // });
  },
  beforeDestroy() {
    this.$root.$off("main-alert");
  },

  watch: {
    alert: {
      handler: function (val) {
        toastr.options = {
          closeButton: true,
          debug: false,
          newestOnTop: false,
          progressBar: true,
          positionClass: "toast-top-right",
          preventDuplicates: false,
          onclick: null,
          showDuration: "300",
          hideDuration: "1000",
          timeOut: "5000",
          extendedTimeOut: "1000",
          showEasing: "swing",
          hideEasing: "linear",
          showMethod: "fadeIn",
          hideMethod: "fadeOut",
        };

        toastr.info(val.message, val.type);
      },
      deep: true,
    },
  },
};
</script>
