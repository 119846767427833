import Vue from "vue";

/****************************************************************************
 * Menangani multi modal
 * - Pemberian class pada body "modal-open" => agar overflow (scroll) di hide
 * - mengincrement & mengupdate z-index pada masing-masing modal
 ****************************************************************************/

const VModalManager = Vue.extend({
  data() {
    return {
      modals: [],
      baseZIndex: 1040,
    };
  },
  computed: {
    modalCount() {
      return this.modals.length;
    },
  },
  watch: {
    modalCount(newCount, oldCount) {
      if (newCount > 0 && oldCount === 0) {
        document.body.classList.add("modal-open");
      } else if (newCount === 0 && oldCount > 0) {
        document.body.classList.remove("modal-open");
      }
      document.body.setAttribute("data-modal-open-count", String(newCount));
    },
    modals(newVal) {
      this.$nextTick(() => {
        this.updateModals(newVal || []);
      });
    },
  },
  methods: {
    registerModal(modal) {
      // Register the modal if not already registered
      if (modal && this.modals.indexOf(modal) === -1) {
        // Add modal to modals array
        this.modals.push(modal);
        //ntahlah
        modal.$once("hook:beforeDestroy", () => {
          this.unregisterModal(modal);
        });
      }
    },
    unregisterModal(modal) {
      const index = this.modals.indexOf(modal);
      if (index > -1) {
        // Remove modal from modals array
        this.modals.splice(index, 1);
      }
    },
    // Private methods
    updateModals(modals) {
      modals.forEach((modal, index) => {
        // We update data values on each modal
        modal.zIndex = this.baseZIndex + index;
      });
    },
  },
});

export const ModalManager = new VModalManager();
