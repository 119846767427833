<template>
  <router-link
    :to="item.link ? item.link : ''"
    v-slot="{ href, navigate, isActive, isExactActive, route }"
    :exact="item.isExactActive"
    custom
  >
    <div v-if="!item.children" class="menu-item">
      <a
        :class="[
          'menu-link',
          isActive ? 'active' : '',
          isExactActive ? 'active' : '',
        ]"
        :href="isActive ? '#' : href"
        @click="navigate"
      >
        <span class="menu-icon">
          <i :class="[item.icon, 'fs-3']"></i>
        </span>
        <span class="menu-title">{{ item.label }}</span>
      </a>
    </div>

    <div
      v-else
      data-kt-menu-trigger="click"
      class="menu-item menu-accordion"
      :class="
        item.children.find((x) => route.fullPath.includes(x.link))
          ? 'here show hover'
          : ''
      "
    >
      <span class="menu-link">
        <span class="menu-icon">
          <i :class="[item.icon, 'fs-3']"></i>
        </span>
        <span class="menu-title">{{ item.label }}</span>
        <span class="menu-arrow"></span>
      </span>
      <div
        class="menu-sub menu-sub-accordion"
        :class="
          item.children.find((x) => route.fullPath.includes(x.link))
            ? 'show'
            : ''
        "
      >
        <menu-item
          v-for="(child, id) in item.children"
          :key="id"
          :item="child"
        />
      </div>
      <!--    RECURSIVE COMPONENT    -->
    </div>
  </router-link>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    item: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
};
</script>
