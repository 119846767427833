<template>
  <div class="swal-alert"></div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "OverlayLoading",

  data() {
    return {
      percent: 0,
      show: false,
      isSuccess: true,
      duration: 3000,
      successColor: "#07ff51",
      failedColor: "#ff0000",
    };
  },

  methods: {
    start() {
      Swal.fire({
        title: "Loading",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      this.show = true;
      this.isSuccess = true;
      if (this._timer) {
        clearInterval(this._timer);
        this.percent = 0;
      }
      this._cut = 10000 / Math.floor(this.duration);
      this._timer = setInterval(() => {
        this.increase(this._cut * Math.random());
        if (this.percent > 95) {
          this.finish();
        }
      }, 100);
      return this;
    },
    set(num) {
      Swal.close();
      this.show = true;
      this.isSuccess = true;
      this.percent = Math.floor(num);
      return this;
    },
    get() {
      return Math.floor(this.percent);
    },
    increase(num) {
      this.percent = this.percent + Math.floor(num);
      return this;
    },
    decrease(num) {
      this.percent = this.percent - Math.floor(num);
      return this;
    },
    finish() {
      Swal.close();
      this.percent = 100;
      this.hide();
      return this;
    },
    pause() {
      clearInterval(this._timer);
      return this;
    },
    hide() {
      clearInterval(this._timer);
      this._timer = null;
      setTimeout(() => {
        this.show = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.percent = 0;
          }, 200);
        });
      }, 500);
      return this;
    },
    fail() {
      this.isSuccess = false;
      this.percent = 100;
      Swal.fire({
        title: "Error",
        text: "Terdapat data yang tidak valid",
        icon: "error",
        confirmButtonText: "Ok",
        buttonsStyling: !1,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          confirmButton: "btn btn-primary order-2",
        },
      });
      return this;
    },
    submit() {
      Swal.fire({
        title: "Berhasil",
        text: "Data berhasil disimpan",
        icon: "success",
        confirmButtonText: "Ok",
        buttonsStyling: !1,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          confirmButton: "btn btn-primary order-2",
        },
      })
    }
  },
};
</script>
