import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";

// import * as rules from 'vee-validate/dist/rules';
import {
  required,
  confirmed,
  email,
  min,
  max,
  min_value,
  max_value,
  alpha_num,
  alpha_dash,
} from "vee-validate/dist/rules.js";

// import indonesia from 'vee-validate/dist/locale/id.json';
// transliterasi perlu dicustom sesuai kebutuhan
const indonesia = {
  code: "id",
  messages: {
    alpha: "{_field_} hanya boleh mengandung karakter alfabet",
    alpha_num: "{_field_} hanya boleh mengandung karakter alfanumerik",
    alpha_dash:
      "{_field_} boleh mengandung karakter alfanumerik, tanda hubung, dan garis bawah",
    alpha_spaces: "{_field_} hanya boleh berisi karakter alfabet serta spasi",
    between: "{_field_} harus diantara {min} dan {max}",
    confirmed: "{_field_} tidak cocok dengan {target}",
    digits: "{_field_} harus berupa {length} digit angka",
    dimensions:
      "{_field_} harus berdimensi lebar {width} pixel dan tinggi {height} pixel",
    email: "{_field_} harus berupa alamat surel yang benar",
    excluded: "{_field_} harus berupa nilai yang sah",
    ext: "{_field_} harus berupa berkas yang benar",
    image: "{_field_} harus berupa gambar",
    integer: "{_field_} harus berupa bilangan bulat",
    length: "Panjang {_field_} harus tepat {length}",
    max_value: "Nilai {_field_} tidak boleh lebih dari {max}",
    max: "{_field_} tidak boleh lebih dari {length} karakter",
    mimes: "Tipe berkas {_field_} harus benar",
    min_value: "Nilai {_field_} tidak boleh kurang dari {min}",
    min: "{_field_} minimal mengandung {length} karakter",
    numeric: "{_field_} harus berupa angka",
    oneOf: "{_field_} harus berupa nilai yang sah",
    regex: "Format {_field_} salah",
    required: "{_field_} harus diisi",
    required_if: "{_field_} harus diisi",
    size: "{_field_} harus lebih kecil dari {size} KB",
  },
};

localize("id", indonesia);

// Install VeeValidate rules and localization
// { alpha_dash, alpha_num, alpha_spaces, alpha, between, confirmed, digits, dimensions, email, ext, image, oneOf, integer, length, is_not, is, max, max_value, mimes, min, min_value, excluded, numeric, regex, required, required_if, size };
// Object.keys(rules).forEach(rule => {
//     extend(rule, rules[rule]);
// });

extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("min_value", min_value);
extend("max_value", max_value);
extend("alpha_num", alpha_num);
extend("alpha_dash", alpha_dash);
extend("confirmed", confirmed);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.mixin({
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
});
