<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-column-fluid bgi-no-repeat">
      <div
        class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
      >
        <div
          href="#"
          class="mb-10 d-flex w-100 justify-content-center gap-10 flex-wrap"
        >
          <img
            alt="Logo"
            src="../assets/img/logo1.png"
            class="h-md-90px h-60px"
          />
          <img alt="Logo" src="../assets/img/er.png" class="h-md-90px h-60px" />
        </div>
        <div class="w-lg-700px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <form
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_up_form"
            v-on:submit.prevent
          >
            <div class="mb-10 text-center">
              <h1 class="text-dark mb-3">Sign Up Absensi Pegawai</h1>
              <div class="text-gray-400 fw-bold fs-4">
                Sudah Punya Akun?
                <a href="#" @click="login" class="link-primary fw-bolder"
                  >Sign in</a
                >
              </div>
            </div>
            <div class="row fv-row mb-7">
              <div class="col-xl-6">
                <label class="form-label fw-bolder text-dark fs-6"
                  >Badge Number</label
                >
                <validation-provider
                  name="Badge Number"
                  :rules="{ required: true, min: 5, max: 64 }"
                  v-slot="validationContext"
                >
                  <b-form-group label-for="badge_number">
                    <b-form-input
                      id="badge_number"
                      placeholder="Masukkkan Badge Number"
                      type="number"
                      autocomplete="off"
                      class="form-control form-control-lg form-control-solid"
                      v-model="user.badge_number"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <div class="col-xl-6">
                <label class="form-label fw-bolder text-dark fs-6">Nama</label>
                <validation-provider
                  name="Nama"
                  :rules="{
                    required: true,
                  }"
                  v-slot="validationContext"
                >
                  <b-form-group label-for="name">
                    <b-form-input
                      id="name"
                      placeholder="Masukkan Nama Lengkap..."
                      autocomplete="off"
                      class="form-control form-control-lg form-control-solid"
                      v-model="user.name"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>
            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6"
                >Username</label
              >
              <validation-provider
                name="Username"
                :rules="{
                  required: true,
                  min: 5,
                  max: 64,
                  alpha_dash: true,
                }"
                v-slot="validationContext"
              >
                <b-form-group label-for="username">
                  <b-form-input
                    id="username"
                    placeholder="Buat Username..."
                    autocomplete="off"
                    class="form-control form-control-lg form-control-solid"
                    v-model="user.username"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6">Email</label>
              <validation-provider
                name="Email"
                :rules="{
                  required: true,
                  email: true,
                }"
                v-slot="validationContext"
              >
                <b-form-group label-for="email">
                  <b-form-input
                    id="email"
                    placeholder="Email anda..."
                    autocomplete="off"
                    class="form-control form-control-lg form-control-solid"
                    v-model="user.email"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6">No Hp</label>
              <validation-provider
                name="No Hp"
                :rules="{
                  required: true,
                }"
                v-slot="validationContext"
              >
                <b-form-group label-for="no_hp">
                  <b-form-input
                    id="no_hp"
                    placeholder="08..."
                    autocomplete="off"
                    class="form-control form-control-lg form-control-solid"
                    v-model="user.no_hp"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6">Jabatan</label>
              <validation-provider
                name="Jabatan"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group label-for="jabatan">
                  <b-form-input
                    type="text"
                    v-model="user.id_jabatan"
                    :state="getValidationState(validationContext)"
                    style="display: none"
                  ></b-form-input>
                  <v-select
                    v-model="user.id_jabatan"
                    class="form-control p-2 form-control-solid"
                    :class="{
                      'is-invalid': !!validationContext.errors.length,
                    }"
                    :options="jabatan"
                    :loading="!jabatan.length"
                    :reduce="(jabatan) => jabatan.id_jabatan"
                    placeholder="Pilih Jabatan..."
                    label="nama_jabatan"
                    :required="!user.id_jabatan"
                    :state="getValidationState(validationContext)"
                  >
                  </v-select>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6"
                >Password</label
              >
              <validation-provider
                name="Password"
                :rules="{ required: true, min: 6, max: 64 }"
                v-slot="validationContext"
              >
                <b-form-group label-for="password">
                  <b-form-input
                    id="password"
                    type="password"
                    placeholder="Buat Password baru"
                    autocomplete="off"
                    class="form-control form-control-lg form-control-solid"
                    v-model="user.password"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6"
                >Confirm Password</label
              >
              <validation-provider
                name="Konfirmasi Password"
                :rules="{
                  required: true,
                  min: 6,
                  max: 64,
                  confirmed: 'Password',
                }"
                v-slot="validationContext"
              >
                <b-form-group label-for="confirm_password">
                  <b-form-input
                    id="confirm_password"
                    type="password"
                    data-vv-as="password"
                    ref="Password"
                    placeholder="Masukkan Password lagi..."
                    autocomplete="off"
                    class="form-control form-control-lg form-control-solid"
                    v-model="user.confirm_password"
                    :state="getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <!-- <div class="fv-row mb-10">
              <label class="form-check form-check-custom form-check-solid form-check-inline">
                <input class="form-check-input" type="checkbox" name="toc" value="1" />
                <span class="form-check-label fw-bold text-gray-700 fs-6">I Agree
                  <a href="#" class="ms-1 link-primary">Terms and conditions</a>.</span>
              </label>
            </div> -->
            <div class="text-center">
              <button
                type="submit"
                @click="simpan"
                id="sub"
                class="btn btn-lg btn-primary"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress"
                  >Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span
                ></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "RegisterComponent",
  data() {
    return {
      user: {
        badge_number: this.$store.state.auth.badge_number || "",
        name: this.$store.state.auth.name || "",
        username: this.$store.state.auth.username || "",
        email: this.$store.state.auth.email || "",
        no_hp: this.$store.state.auth.no_hp || "",
        password: "",
        confirm_password: "",
        id_jabatan: this.$store.state.auth.id_jabatan,
        tanggal_mulai: this.$store.state.auth.tanggal_mulai,
        tanggal_selesai: this.$store.state.auth.tanggal_selesai,
      },
      jabatan: [],
      dateEndConfig: this.$App.config.dateEndConfig,
    };
  },

  methods: {
    login() {
      this.$store.commit("setAuth", {
        status: "login",
      });
    },
    verifikasi() {
      this.$store.commit("setAuth", {
        status: "verifikasi",
        ...this.user,
      });
    },
    simpan() {
      var e = document.querySelector("#sub");
      e.setAttribute("data-kt-indicator", "on");
      this.$topprogressbar.start();
      this.$overlayloading.start();
      this.$axios
        .post("/register", this.user)
        .then(() => {
          Swal.fire({
            title: "Berhasil",
            text: "Anda berhasil mendaftar, silahkan cek email anda untuk verifikasi",
            icon: "success",
            buttonsStyling: !1,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            this.$store.commit("setAuth", {
              status: "verifikasi",
              ...this.user,
            });
            e.removeAttribute("data-kt-indicator");
            this.$topprogressbar.finish();
          });
        })
        .catch((err) => {
          e.removeAttribute("data-kt-indicator");
          this.$topprogressbar.fail();
          this.$overlayloading.finish();
          Swal.fire({
            title: "Gagal",
            text: err.response.data.message,
            icon: "error",
            buttonsStyling: !1,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        });
    },
  },
  mounted() {
    this.$axios.get("/jabatan").then((res) => {
      this.jabatan = res.data.data;
    });
  },
};
</script>

<style scoped>
.v-select >>> .vs__selected-options,
.v-select >>> .vs__selected-options input.vs__search,
.v-select >>> .vs__dropdown-menu,
.v-select >>> .vs__actions,
.v-select >>> .vs__dropdown-toggle {
  background-color: #f5f8fa !important;
}
</style>
