import Vue from "vue";
import Axios from "axios";
import Store from "../store";
import Config from "../config";
import Swal from "sweetalert2";
import { isMobile } from "mobile-device-detect";

const token = Store.state.token ? Store.state.token : undefined;

const AxiosInstance = Axios.create({
  baseURL: Config.baseUrlApi, //"http://127.0.0.1:3009/api/",
  timeout: 100000,
  withCredentials: false,
  headers: {
    "Authorization": "Bearer " + token,
    "Made-By": "Alfad Sabil Haq",
  },
});

const buildInterceptors = (vm) => {
  const messageNetworkError = vm.$createElement("p", {
    domProps: {
      innerHTML:
        "Terjadi kegagalan mengakses server<br> bisa jadi karena : <div class='text-center'><br>- Tidak ada koneksi pada perangkat anda. <br>\n- Koneksi perangkat lambat. <br>\n- Server sedang down.</div>",
    },
  });

  const onRequest = (config) => {
    // Do something before request is sent
    config.disableErrNotif = config.disableErrNotif || false;
    config.disableExptimestamp = config.disableExptimestamp || false;
    config.loading = config.loading || false;
    config.headers["FE-Type"] = isMobile ? "Web Mobile" : "Desktop";

    if (config.loading) {
      Store.commit("setLoading", true);
    }

    return config;
  };
  const onRequestError = (error) => {
    // Do something with request error
    return Promise.reject(error);
  };
  const onResponse = (response) => {
    if (response.config.loading && Store.state.loading === true) {
      Store.commit("setLoading", false);
    }

    if (!response.config.disableExptimestamp) {
      Store.commit("setExptimestamp", Date.now() + 1 * 60 * 59 * 1000);
    }

    return response;
  };
  const onResponseError = (error) => {
    /********************************
     * - config
     * - request
     * - response
     * - isAxiosError
     * - toJSON
     *******************************/

    if (error.config.loading && Store.state.loading === true) {
      Store.commit("setLoading", false);
    }

    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */

      switch (error.response.status) {
        case 401:
          Store.commit("setExptimestamp", 0);
          Store.commit("setToken", "");
          Swal.fire({
            icon: "error",
            title: "Error - " + error.response.status,
            text: error.response.data.message,
            backdrop: !0,
            buttonsStyling: !1,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn font-weight-bold btn-primary",
            },
            allowEnterKey: true,
            allowEscapeKey: true,
            allowOutsideClick: true,
          });
          break;
        case 498:
          Store.commit("setExptimestamp", 0);
          Store.commit("setToken", "");
          break;
        case 503:
          vm.$root.$emit("notif-modal", {
            statusCode: "Service Unavailable",
            message: messageNetworkError,
          });
          Swal.fire({
            icon: "error",
            title: "Error - " + error.response.status,
            text: messageNetworkError.data.domProps.innerHTML,
            backdrop: !0,
            buttonsStyling: !1,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn font-weight-bold btn-primary",
            },
            allowEnterKey: true,
            allowEscapeKey: true,
            allowOutsideClick: true,
          });
          break;
        default:
          if (!error.config.disableErrNotif && error.response.data.message) {
            // vm.$root.$emit("notif-modal", {
            //   statusCode: error.response.status,
            //   message: error.response.data.message,
            // });

            Swal.fire({
              icon: "error",
              title: "Error - " + error.response.status,
              text: error.response.data.message,
              backdrop: !0,
              buttonsStyling: !1,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn font-weight-bold btn-primary",
              },
              allowEnterKey: true,
              allowEscapeKey: true,
              allowOutsideClick: true,
            });
          }
          if (!error.config.disableExptimestamp) {
            Store.commit("setExptimestamp", Date.now() + 1 * 60 * 59 * 1000);
          }

          break;
      }

      if (error.response.request.responseType === "blob") {
        Swal.fire({
          icon: "error",
          title: "Error - " + error.response.status,
          text: error.response.data.message ?? "Terjadi kesalahan pada server",
          buttonsStyling: !1,
          backdrop: !0,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn font-weight-bold btn-primary",
          },
          allowEnterKey: true,
          allowEscapeKey: true,
          allowOutsideClick: true,
        });
      }

      if (error.response.data) {
        /******************
         * error.config.responseType =>
         * 'arraybuffer', 'document', 'json', 'text', 'stream'
         * browser only: 'blob'
         */

        //  dibawah ini +19kb
        if (error.config.responseType === "arraybuffer") {
          try {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(error.response.data)
            );
            error.response.data = JSON.parse(decodedString);
          } catch (e) {
            // empty
          }
        }
      }

      return Promise.reject(error);
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */

      // bisa jadi karena Network problems atau OPTIONS request is failed
      // https://stackoverflow.com/questions/52353631/in-what-cases-an-http-request-is-made-but-no-response-is-received
      // net::ERR_CONNECTION_REFUSED / api gak aktif & webserver bales refused

      if (error.message == "Network Error") {
        if (!error.config.disableErrNotif) {
          // vm.$root.$emit("notif-modal", {
          //   statusCode: "Error Network",
          //   message: messageNetworkError,
          // });

          Swal.fire({
            icon: "error",
            title: "Error Network",
            html: messageNetworkError.data.domProps.innerHTML,
            buttonsStyling: !1,
            backdrop: !0,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn font-weight-bold btn-primary",
            },
            allowEnterKey: true,
            allowEscapeKey: true,
            allowOutsideClick: true,
          });
        }
      } else {
        if (!error.config.disableErrNotif) {
          // vm.$root.$emit("notif-modal", {
          //   statusCode: "Unknown ERROR",
          //   message: error.message,
          // });

          Swal.fire({
            icon: "error",
            title: "Unknown ERROR",
            text: error.message,
            buttonsStyling: !1,
            backdrop: !0,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn font-weight-bold btn-primary",
            },
            allowEnterKey: true,
            allowEscapeKey: true,
            allowOutsideClick: true,
          });
        }
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      // if (process.env.NODE_ENV !== 'production') {
      //   console.warn('Unknown ERROR => ', error);
      // }
      // contoh seperti kirim auth bearer dengan text arab
    }

    return Promise.reject(error);
  };

  /* Add a REQUEST INTERCEPTOR */
  AxiosInstance.interceptors.request.use(onRequest, onRequestError);
  /* Add a RESPONSE INTERCEPTOR */
  AxiosInstance.interceptors.response.use(onResponse, onResponseError);
};

export let _Vue;
function install(vue) {
  if (install.installed && _Vue === vue) {
    return;
  }

  install.installed = true;

  _Vue = vue;

  vue.mixin({
    beforeCreate() {
      if (this.$options.axios) {
        this.__axios = this.$options.axios;
        buildInterceptors(this);
      } else {
        this.__axios = (this.$parent && this.$parent.__axios) || this;
      }
    },
  });

  Object.defineProperty(vue.prototype, "$axios", {
    get() {
      return this.__axios;
    },
  });
}

Vue.use(install);

export default AxiosInstance;
